<template>
  <div class="tb-wrap flex-column">
    <div class="title-head flex-row flex-center">Our Partners</div>
    <div class="tb-body flex-row flex-wrap flex-start"
         style="padding-bottom: 20px;box-sizing: border-box;align-items: flex-start;align-content: flex-start;justify-content: space-between;">
      <div class="partner-item" v-for="(item, index) in partners" :key="'partner-' + index">
        <a :href="item.url" target="_blank">
          <img :src="item.logo_url" />
        </a>
      </div>
      <div class="partner-item" style="visibility: hidden" v-for="item in this.vitualCount" :key="'vitual-partner'-item"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Partners',
  data () {
    return {
    };
  },
  props: {
    partners: Array
  },
  computed: {
    vitualCount () {
      return 6 - this.partners.length % 6;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/base.scss";
@import "~@/assets/scss/home.scss";
.partner-item{
  display: flex;
  width: 180px;
  height: 120px;
  padding: 10px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  align-content: center;
  overflow: hidden;
  a {
    cursor: pointer;
  }
  img{
    width: 100%;
    height: auto;
  }
}
</style>
