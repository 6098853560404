import * as types from '../types';
import * as api from '@/api';

const state = {
  homeData: {},
  info: {}
};

const getters = {
  homeData: state => state.homeData,
  info: state => state.info
};

const actions = {
  fetchHomeData ({ commit, state }, params = {}) {
    return api.fetch(params).then((resp) => {
      commit(types.HOME_DATA_FETCH, resp);
      return Promise.resolve(resp);
    }).catch((e) => {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        message: e.message || 'request error'
      });
    });
  },
  fetchSiteInfo ({ commit, state }, params = {}) {
    return api.info(params).then((resp) => {
      commit(types.WEB_COMMON_INFO, resp);
      return Promise.resolve(resp);
    }).catch((e) => {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        message: e.message || 'network error'
      });
    });
  }
};

const mutations = {
  [types.HOME_DATA_FETCH] (state, res) {
    // console.log(`homeData.res:${JSON.stringify(res)}`);
    state.homeData = res;
  },
  [types.WEB_COMMON_INFO] (state, res) {
    state.info = res;
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
};
