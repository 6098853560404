<template>
<div  class="tb-width-full">
  <!-- Header -->
  <div class="tb-width-full header-wrap">
    <section class="tb-wrap header">
      <div class="logo">
        <img :src="info.login_url" /> <span>GENERAL LININGS</span>
      </div>
      <div class="nav">
        <router-link to="/">HOME</router-link>
        <a href="#" v-scroll-to="{ element: '#service', duration: 250, easing: 'linear' }">SERVICES</a>
        <a href="#" v-scroll-to="{ element: '#gallery', duration: 250, easing: 'linear'}">GALLERY</a>
        <a href="#" v-scroll-to="{ element: '#contact', duration: 250, easing: 'linear'}">CONTACT</a>
      </div>
    </section>
    <!-- //EOF Header -->
    <div class="tb-width-full banner-wrap">
      <!-- Swiper -->
      <swiper class="swiper" ref="bannerSwiper" :options="swiperOption"
              @slideChangeTransitionStart="changSwiperStart"
              @slideChangeTransitionEnd="changeSwiperEnd"
      >
        <!-- swiper pagination -->
        <template v-for="banner in homeData.banners">
          <swiper-slide :key="'banner-' + banner.id" >
            <img :src="banner.banner_url" />
          </swiper-slide>
        </template>
        <div class="swiper-pagination" slot="pagination" :style="{display: isShowBanner ? 'block': 'none'}"></div>
      </swiper>
      <!-- EOF swiper pagination -->

      <!-- Banner Intro -->
      <div class="banner-intro-mask"></div>
      <transition name="bounce" enter-active-class="bounceInRight" leave-active-class="bounceOutLeft">
        <div class="banner-intro" v-show="introShow" :style="{ textAlign: (currentBanner && currentBanner.align) || 'left'}">
          <template v-if="currentBanner">
            <!-- 默认文案 -->
            <div class="banner-intro-main" v-if="currentBanner.title">
              {{ currentBanner.title.toUpperCase() }}
            </div>
            <div class="banner-intro-sub" v-if="currentBanner.middle_title">
              {{ currentBanner.middle_title.toUpperCase() }}
            </div>
            <div class="banner-intro-quote">
              <template v-if="parseInt(currentBanner.is_quote) === 1">
                <a :href="'tel:' + currentBanner.subtitle">
                  <i>Get a quote: </i><B>{{ currentBanner.subtitle}}</B>
                </a>
              </template>
              <template v-else>
                {{ currentBanner.subtitle.toUpperCase() }}
              </template>
            </div>
            <!-- EOF 默认文案 -->
          </template>
        </div>
      </transition>
      <!-- //end Banner Intro -->
    </div>
    <!-- //end Swiper -->
  </div>
  <!-- Content -->
  <router-view />
  <!-- EOF Content -->
  <div class="tb-wrap">
    <Footer :is-mobile="false" />
  </div>
</div>
</template>

<script>
import 'swiper/css/swiper.min.css';
import 'swiper/swiper.scss';
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
import Footer from '@/components/Layouts/Footer';
import { createNamespacedHelpers } from 'vuex';
const homeStore = createNamespacedHelpers('home');

export default {
  name: 'Desktop',
  components: {
    Footer,
    Swiper,
    SwiperSlide
  },
  directives: {
    swiper: directive
  },
  created () {
    this.$store.dispatch('home/fetchSiteInfo', {});
    this.$store.dispatch('home/fetchHomeData', {}).then((res) => {
      this.swiperIndex = 0;
    });
  },
  mounted () {
  },
  computed: {
    ...homeStore.mapGetters(['homeData', 'info'])
  },
  watch: {
    homeData () {
      if (this.homeData.banners) {
        this.swiperIndex = 0;
        this.isShowBanner = true;
        this.introShow = true;
        this.currentBanner = this.homeData.banners[0];
      }
    }
  },
  data () {
    return {
      swiperIndex: -1,
      introShow: false,
      isShowBanner: false,
      swiperOption: {
        spaceBetween: 30,
        // loop: true,
        lazyLoading: true,
        // watchOverflow: true,
        // slidesPerView: 1,
        // centeredSlides: true,
        // loopFillGroupWithBlank: true,
        autoplay: {
          delay: 6000,
          disableOninteraction: true
        },
        speed: 1000,
        pagination: {
          dynamicMainBullets: 1,
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      currentBanner: undefined
    };
  },
  methods: {
    changSwiperStart () {
      this.introShow = false;
    },
    changeSwiperEnd () {
      const swiper = this.$refs.bannerSwiper.$swiper;
      if (swiper && this.homeData && this.homeData.banners) {
        const itemIndex = swiper.realIndex;
        this.swiperIndex = itemIndex;
        this.currentBanner = this.homeData.banners[itemIndex];
        if (!this.currentBanner.title && !this.currentBanner.subtitle) {
          this.currentBanner = undefined;
        }

        if (this.isShowBanner === false) {
          if (this.swiperIndex > -1) {
            this.isShowBanner = true;
          }
        }
        console.log(`itemIndex:${itemIndex}`);
      }

      this.introShow = true;
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/base.scss";
.header-wrap {
  position: relative;
}
.header {
  height: 120px;
  display: flex;
  justify-content: space-between;
//background: aquamarine;
  align-items: center;
  position: absolute;
  z-index: 1100;

}
.logo {
  font-size: 48px;
  //font-family: Helvetica-Bold, Helvetica;
  font-weight: bold;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  img {
    width: 62px;
    height: 75px;
    margin-right: 20px;
  }
}
.nav {
  display: flex;
  line-height: 30px;
  justify-content: space-between;
  font-size: 14px;
//background: antiquewhite;
  align-items: flex-end;
  align-content: flex-end;
  a {
    color: #a9a9a9;
    display: inline-flex;
    margin-right: 40px;
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
    &:last-child {
       margin-right: 0;
    }
    &.router-link-exact-active {
       color: #ffffff;
    }
  }
}
.swiper {
  width: 100%;
  height: 100%;
  position: relative;
}

.swiper-slide {
  background: #6781f8;
  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}

.swiper-button-prev,
.swiper-button-next {
  width: 9px;
  height: 12px;
  z-index: 1100;
  margin: 0 5px;
img {
  width: 100%;
  height: 100%;
}
}

.swiper-controls {
  //width: 150px;
  //height: 25px;
  //display: flex;
  //flex-direction: row;
  //align-items: center;
  //justify-content: center;
  //background: #6f75f1;
  //position: absolute;
  //bottom: 15px;
  ////left: 80%;
  //z-index: 1102;
  //color: #ffffff;
  //font-size: 14px;
  //border-radius: 2px;
}

.swiper-pagination {
  z-index: 1100;
}

//.bullet-actived {
//  background: #FFFFFF;
//  color: #FFFFFF;
//}

.swiper-container {
  --swiper-theme-color: #ff6600; /* 设置Swiper风格 */
  --swiper-navigation-color: #00ff33; /* 单独设置按钮颜色 */
  --swiper-navigation-size: 30px; /* 设置按钮大小 */
}
.banner-wrap {
  position: relative;
  height: 100vh;
}
.banner-intro-mask {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(360deg, #2D2D2D40, #00000087);
  //opacity: 0.34;
  z-index: 1000;
}
.banner-intro {
  position: absolute;
  //background: #42b983;
  left: 0;
  right: 0;
  width: 100%;
  height: auto;
  top: 50%;
  display: flex;
  flex-direction: column;
  //align-items: center;
  //align-content: center;
  text-align: left;
  color: #FFFFFF;
  padding-left: 10%;
  padding-right: 10%;
  box-sizing: border-box;
  align-items: center;

  .banner-intro-main {
    font-size: 42px;
    font-weight: bold;
    width: 1200px;
  }
  .banner-intro-sub {
    margin-top: 30px;
    font-size: 35px;
    font-weight:  bold;
    width: 1200px;
  }
  .banner-intro-quote {
    margin-top: 20px;
    font-size: 28px;
    font-style: normal;
    width: 1200px;
    a:link{
      color: #FFFFFF;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  z-index: 1001;
}
</style>
