<template>
  <div class="social-medias" v-show="info">
    <div class="title-head">Follow Us</div>
    <div class="social-icons">
      <font-awesome-icon  :icon="['fab', 'facebook']" v-show="info.facebook" @click="redirect(info.facebook)"/>
      <font-awesome-icon  :icon="['fab', 'twitter']" v-show="info.twitter" @click="redirect(info.twitter)"/>
      <font-awesome-icon  :icon="['fab', 'instagram']" v-show="info.instagram" @click="redirect(info.instagram)"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FollowUs',
  props: {
    info: {
      type: Object
    }
  },
  methods: {
    redirect (url) {
      window.location.href = url;
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/base.scss";
.social-icons{
  margin-bottom: pr2(50);
  font-size: pr2(40);
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  height: pr2(80);
  width: pr2(300);
  color: $siteColor;
  cursor: hand;
  svg:hover{
    color: #4DABF7;
  }
}
</style>
