<template>
  <div class="flex-column">
  <div class="title-head">Our Partners</div>
  <div class="flex-row flex-wrap flex-start">
    <div class="partner-item" v-for="(item, index) in partners" :key="'partner-' + index">
      <a :href="item.url" target="_blank">
        <img :src="item.logo_url" />
      </a>
    </div>
    <div class="partner-item" style="visibility: hidden" v-for="item in this.vitualCount" :key="'vitual-partner'-item"></div>
  </div>
  </div>
</template>

<script>
export default {
  name: 'PartnerMobile',
  props: {
    partners: {
      type: Array,
      default () {
        return [];
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/base.scss";
.partner-item{
  display: flex;
  width: 180px;
  height: 120px;
  padding: 10px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  align-content: center;
  overflow: hidden;
  a {
    cursor: pointer;
  }
  img{
    width: 100%;
    height: auto;
  }
}
</style>
