<template>
  <div class="tb-width-full tb-body">
    <!-- Service -->
    <div class="tb-wrap service" id="service" v-if="homeData.service">
      <div class="title-head">Service</div>
      <div class="title-sudhead" style="margin-bottom: 20px">Drawing on decades of experience within our team,	we pride ourselves on delivering quality outcomes for our clients</div>
      <div class="service-wrap service-wrap-column">
        <template v-for="(service, index) in homeData.service">
          <div class="service-poster"
               :style="{ backgroundImage: 'url(' + service.image_url + ')' }"
               :class="index%2===0 ? 'flex-start' : 'flex-end'"
               :key="'service-' + service.id">
            <div class="service-poster-desc"
                 >
              <div class="service-poster-title">
                {{ service.title }}
              </div>
              <div class="service-poster-content">
                {{ service.content }}
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <!-- //end Service -->
    <!-- About -->
    <div class="tb-wrap about">
      <div class="intro">
        <div class="intro-title">
          Your home construction partner
        </div>
        <div class="intro-content">
          We specialise in residential, retail, and commercial interior
          construction.<br /><br />
          We offer excellent construction solutions to satisfy every customers'
          needs.<br /><br />
          With plenty of options we provide, you can build your unique indoor
          area with ease.
        </div>
        <div class="intro-more">
          Get more
        </div>
      </div>
      <div class="thumb">
        <img src="@/assets/images/about.png" class="cover" />
        <img src="@/assets/images/mask.png" class="mask" />
      </div>
    </div>
    <!-- //end About -->
    <!-- Cases Count-->
    <div class="tb-width-full case-banner">
      <div class="tb-wrap">
        <div class="case-banner-intro">
<!--          <div class="simbol">“</div>-->
          <div>Transforming people's dream premises & homes into reality.</div>
        </div>
        <div class="case-banner-item-wrap">
          <!-- banner-item -->
          <div class="case-banner-item">
            <div class="item-count">
              300+
            </div>
            <div class="item-title">
              PROJECTS COMPLETED
            </div>
          </div>
          <div class="case-banner-item">
            <div class="item-count">
              1000+
            </div>
            <div class="item-title">
              CONTRACTORS APPOINTED
            </div>
          </div>
          <!-- //end Banner item -->
        </div>
      </div>
    </div>
    <!-- //end Cases Count -->
    <Gallery />
    <Contact />
    <FollowUs :info="info"/>
    <Partners :partners="homeData.partners"/>

    <div class="pop-modal" v-show="showModal">
      <transition
        name="bounce"
        enter-active-class="bounceInDown"
        leave-active-class="bounceOut"
      >
      <div class="pop-content-wrap"  v-show="showModal">
        <i class="el-icon-circle-close" @click="closeModal"></i>
        <div class="thumb">
            <img :src="service.image_url">
        </div>
        <div class="content">
            <div class="title-head" v-html="service.title"></div>
            <div class="content-desc" v-html="service.content"></div>
        </div>
      </div>
      </transition>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src

import Gallery from '@/components/Gallery';
import Contact from '@/components/Contact';
import FollowUs from '@/components/FollowUs';
import { createNamespacedHelpers } from 'vuex';
import Partners from '@/components/Partners';
const homeStore = createNamespacedHelpers('home');

export default {
  name: 'Home',
  components: {
    Partners,
    Contact,
    Gallery,
    FollowUs
  },
  data () {
    return {
      listType: parseInt(this.$route.query.type) || 0,
      showModal: false,
      service: {}
    };
  },
  created () {
  },
  mounted () {
    console.log(`listType:${this.listType}--${typeof this.listType}`);
  },
  computed: {
    ...homeStore.mapGetters(['homeData', 'info'])
  },
  methods: {
    showService (service) {
      this.showModal = true;
      this.service = service;
    },
    closeModal () {
      this.showModal = false;
      this.service = {};
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/base.scss";
@import "~@/assets/scss/home.scss";
</style>
