<template>
  <div class="tb-wrap contact" id="contact">
    <!-- contact info -->
    <div class="contact-info">
      <div class="title-head">Contact Us</div>
      <div class="title-sudhead" style="margin-bottom: 30px;">
      </div>
      <div class="title-sudhead">
        For any inquiries, questions or commendations,<br/>
        Please call: <B>{{ info.quote_mobile }}</B>
        or fill out the following form
      </div>
      <div class="title-2nd">Head Office</div>
      <div class="title-sudhead">
        {{ info.address }}
      </div>
      <div class="title-2nd">Get a quote:&nbsp;<a :href="'tel:' + info.quote_mobile">{{ info.quote_mobile }}</a></div>
    </div>
    <!-- // end contact info -->
    <!-- contact form -->
    <div class="contact-form">
      <div class="input-control">
        <input type="text" placeholder="Enter Your Name" v-model="username" />
      </div>
      <div class="input-control">
        <input type="text" placeholder="Enter Your Email" v-model="email" />
      </div>
      <div class="input-control">
        <input type="text" placeholder="Enter Your Subject" v-model="title"/>
      </div>
      <div class="input-control-textarea">
        <textarea rows="20" placeholder="Enter your message" v-model="content"> </textarea>
      </div>
      <div class="input-control-submit">
        <div class="tb-button-primary" @click="sendQuotes">Send</div>
      </div>
    </div>
    <!-- //end contact form -->
  </div>
</template>

<script>
import * as api from '@/api';
import { quote } from '@/api';
import { createNamespacedHelpers } from 'vuex';
const homeStore = createNamespacedHelpers('home');
export default {
  name: 'Contact',
  data () {
    return {
      username: '',
      email: '',
      title: '',
      content: ''
    };
  },
  computed: {
    ...homeStore.mapGetters(['homeData', 'info'])
  },
  methods: {
    sendQuotes () {
      const params = {
        username: this.username,
        email: this.email,
        title: this.title,
        content: this.content
      };

      api.quote(params).then((res) => {
        console.log(`res:${res}`);
        this.$message({
          showClose: true,
          message: 'Submit success, Thanks for your message',
          type: 'success'
        });
        this.username = '';
        this.email = '';
        this.content = '';
        this.title = '';
      }).catch((e) => {
        console.log(`error:${e}`);
        this.$message({
          showClose: true,
          message: 'service is busy, please try later',
          type: 'error'
        });
      });
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/base.scss";
.contact {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 50px 0;
}

.contact-info {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 510px;
  .title-2nd {
    margin-top: 30px;
    line-height: 30px;
  }
}
.contact-form {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 500px;
  justify-content: space-between;
}

@mixin input-style {
  //font-family: STHeitiTC-Light, STHeitiTC;
  font-family: 'Questrial', sans-serif;
  border: 1px solid #cacad3;
  overflow: hidden;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  margin-top: 10px;
  padding: 0 10px 0 10px;
  input,
  textarea {
    border: none;
    outline: none;
    width: 100%;
    height: 100%;
    color: #222222;
    font-size: 16px;
    &::placeholder {
      font-size: 14px;
      color: #797b82;
      //font-family: STHeitiTC-Light, STHeitiTC;
      font-family: 'Questrial', sans-serif;
    }
  }
}
.input-control-textarea {
  @include input-style();
  height: 100px;
  padding-top: 10px;
}
.input-control {
  @include input-style();
  width: 100%;
  height: 42px;
}
.input-control-submit {
  height: 44px;
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-content: flex-end;
  margin-top: 10px;
}
.title-2nd > a:link {
  color: $textColor;
  font-weight: bold;
  text-decoration: none;
  &:hover{
    text-decoration: underline;
  }
}
</style>
