<template>
  <div class="tb-width-full">
    <!-- Swiper -->
    <div class="swiper-container">
<!--      <transition name="fadeIn menu-show">-->
        <div class="nav-menu-wrap" ref="menuWrap">
          <div class="menu-item">HOME</div>
          <div class="menu-item">
            <a href="#" v-scroll-to="{ element: '#service', duration: 1500, easing: 'ease-in-out' }">SERVICES</a>
          </div>
          <div class="menu-item">
            <a href="#" v-scroll-to="{ element: '#gallery', duration: 1000, easing: 'ease-in-out'}">GALLERY</a>
          </div>
          <div class="menu-item">
            <a href="#" v-scroll-to="{ element: '#contact', duration: 1500, easing: 'ease-in-out'}">CONTACT</a>
          </div>
        </div>
<!--      </transition>-->
      <div class="nav-button" ref="menuButton" @click.stop="showMenu">
        <img :src="menuShow ? require('@/assets/images/menu_close@2x.png') : require('@/assets/images/menu@2x.png') ">
      </div>
      <div class="logo" ref="logo">
        <img :src="info.login_url" /> <span>GENERAL LININGS</span>
      </div>
      <swiper class="swiper" :options="swiperOption"
              @slideChangeTransitionStart="changSwiperStart"
              @slideChangeTransitionEnd="changeSwiperEnd">
        <template v-for="banner in homeData.banners">
          <swiper-slide :key="'banner-' + banner.id" >
            <img :src="banner.banner_url" />
          </swiper-slide>
        </template>
      </swiper>
<!--      <div class="swiper-controls">-->
<!--        <div class="swiper-pagination" slot="pagination">-->
<!--        </div>-->
<!--      </div>-->
      <!-- Banner Intro -->
      <div class="banner-intro-mask"></div>
      <transition name="fadeIn" enter-active-class="fadeInRight" leave-active-class="fadeOutLeft">
        <div class="banner-intro" v-show="introShow">
          <template v-if="swiperIndex == 0">
            <div class="banner-intro-main">
              YOUR INTERIOR LININGS
            </div>
            <div class="banner-intro-sub">
              SPECIALIIST
            </div>
            <div class="banner-intro-quote">
              <B>RAWING ON DECADES OF EXPERIENCE WITHIN OUR TEAM</B>
<!--              <a :href="'tel:' + info.quote_mobile"><i>Get a quote: </i><B>{{ info.quote_mobile }}</B></a>-->
            </div>
          </template>
          <template v-else>
            <div class="banner-intro-main">
              {{ homeData.banners[swiperIndex].title}}
            </div>
          </template>
        </div>
      </transition>
      <!-- //end Banner Intro -->
    </div>
    <!-- //end Swiper -->
    <!-- Service -->
    <div class="service tb-width-full" id="service">
      <div class="title-head" style="margin-top: 50px;">Service</div>
      <div class="service-wrap">
        <template v-for="service in homeData.service">
          <div class="service-item" :key="'service-' + service.id">
            <img :src="service.image_url" />
            <div class="mask"></div>
            <div class="title">{{ service.title }}</div>
            <div class="description">{{ service.content }}</div>
          </div>
        </template>
      </div>
    </div>
    <!-- //end Service -->
    <!-- Intro -->
    <!-- About -->
    <div class="tb-width-full about">
      <div class="intro">
        <div class="intro-title">
          Your home construction partner
        </div>
        <div class="intro-content">
          {{ info.intro }}
        </div>
        <div class="intro-more">
          Get more
        </div>
      </div>
      <div class="thumb">
        <img src="@/assets/images/about.png" class="cover" />
        <img src="@/assets/images/mask.png" class="mask" />
      </div>
    </div>
    <!-- //end About -->
    <!--//end Intro -->
    <!-- Cases Count-->
    <div class="tb-width-full case-banner">
        <div class="case-banner-intro">
          <div class="simbol">“</div>
          <div>Transforming people's dream premises & homes into reality.</div>
        </div>
        <div class="case-banner-item-wrap">
          <!-- banner-item -->
          <div class="case-banner-item">
            <div class="item-count">
              300+
            </div>
            <div class="item-title">
              PROJECTS COMPLETED
            </div>
          </div>
          <div class="case-banner-item">
            <div class="item-count">
              1000+
            </div>
            <div class="item-title">
              CONTRACTORS APPOINTED
            </div>
          </div>
          <!-- //end Banner item -->
      </div>
    </div>
    <!-- //end Cases Count -->
    <!-- Gallery -->
    <div class="tb-width-full gallery" id="gallery">
      <div class="gallery-body">
        <div class="title-head">Our Gallery</div>
        <div class="title-sudhead">We have implemented more wonderful works</div>
        <!-- Gellery Items -->
        <div class="gallery-wrap">
          <!-- Gallery Item -->
          <template v-for="item in homeData.gallery">
            <div class="gallery-item" :key="'gallery-' + item.id">
              <div class="thumb">
                <img :src="item.cover_url" />
              </div>
              <div class="intro">
                <div class="intro-title">{{ item.name }}</div>
                <div class="gallery-content">
                  {{ item.content }}
                </div>
              </div>
            </div>
          </template>
          <!-- //end Gallery Item -->
        </div>
        <!-- //end Gallery Items -->
      </div>
    </div>
    <!-- //end Gallery -->
    <!-- Contact -->
    <div class="tb-width-full contact" id="contact">
      <!-- contact info -->
      <div class="contact-info">
        <div class="title-head" style="text-align: center">Contact Us</div>
        <div class="title-sudhead">
          For any inquiries, questions or commendations,
          Please call: <B>{{ info.quote_mobile }}</B>
          or fill out the following form
        </div>
        <div class="title-2nd">Head Office</div>
        <div class="title-sudhead">
          {{ info.address }}
        </div>
        <div class="title-2nd">
          <a :href="'tel:' + info.quote_mobile">Get a quote: {{ info.quote_mobile }}</a></div>
      </div>
      <!-- // end contact info -->
      <!-- contact form -->
      <div class="contact-form">
        <div class="input-control">
          <input type="text" placeholder="Enter Your Name" v-model="username" />
        </div>
        <div class="input-control">
          <input type="text" placeholder="Enter Your Email" v-model="email"/>
        </div>
        <div class="input-control">
          <input type="text" placeholder="Enter Your Subject" v-model="title"/>
        </div>
        <div class="input-control-textarea">
          <textarea rows="20" placeholder="Enter your message" v-model="content"/>
        </div>
        <div class="input-control-submit">
          <div class="tb-button-primary" @click="sendQuotes">Send</div>
        </div>
      </div>
    </div>
    <!-- //end Contact -->
    <FollowUs :info="info"/>
    <PartnerMobile :partners="homeData.partners" />
    <Footer :is-mobile="true" />
  </div>
</template>

<script>
// import Footer from '@/components/Layouts/Footer';
import 'swiper/swiper.scss';
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
import { createNamespacedHelpers } from 'vuex';
import * as api from '@/api';
import FollowUs from '@/components/FollowUs';
import Footer from '@/components/Layouts/Footer';
import Partners from '@/components/Partners';
import PartnerMobile from '@/components/PartnerMobile';
const homeStore = createNamespacedHelpers('home');
export default {
  name: 'Mobile.vue',
  components: {
    PartnerMobile,
    Partners,
    Footer,
    FollowUs,
    Swiper,
    SwiperSlide
  },
  directive: {
    swiper: directive
  },
  mounted () {
    this.$store.dispatch('home/fetchSiteInfo', {});
    this.$store.dispatch('home/fetchHomeData', {});
  },
  data () {
    return {
      menuShow: false,
      introShow: false,
      swiperIndex: 0,
      swiperOption: {
        spaceBetween: 30,
        loop: true,
        slidesPerView: 1,
        autoplay: {
          delay: 3000,
          disableOninteraction: true
        },
        speed: 800,
        keyboard: {
          enabled: true
        },
        pagination: {
          el: '.swiper-pagination',
          type: 'fraction'
        }
      },
      username: '',
      email: '',
      title: '',
      content: ''
    };
  },
  computed: {
    ...homeStore.mapGetters(['homeData', 'info'])
  },
  methods: {
    showMenu () {
      console.log('showMenu');
      if (this.$refs.menuButton) {
        this.menuShow = !this.menuShow;
        const className = this.menuShow ? 'nav-menu-active' : 'nav-menu-normal';
        this.$refs.menuButton.setAttribute('class', `nav-button ${className}`);

        const wrapClass = this.menuShow ? 'nav-menu-wrap-active' : 'nav-menu-wrap-normal';
        this.$refs.menuWrap.setAttribute('class', `nav-menu-wrap ${wrapClass}`);

        if (this.menuShow) {
          this.$refs.logo.style.display = 'none';
        } else {
          setTimeout(() => {
            this.$refs.logo.style.display = '';
          }, 500);
        }
      }
    },
    sendQuotes () {
      const params = {
        username: this.username,
        email: this.email,
        title: this.title,
        content: this.content
      };

      api.quote(params).then((res) => {
        console.log(`res:${res}`);
        this.$message({
          showClose: true,
          message: 'Submit success, Thanks for your message',
          type: 'success'
        });
        this.username = '';
        this.email = '';
        this.content = '';
        this.title = '';
      }).catch((e) => {
        console.log(`error:${e}`);
        this.$message({
          showClose: true,
          message: 'service is busy, please try later',
          type: 'error'
        });
      });
    },
    changSwiperStart () {
      // console.log(`changeSwiper--SwiperIndex:${this.swiperIndex}`);
      this.introShow = false;
    },
    changeSwiperEnd () {
      // console.log(`changeSwiperEnd--SwiperIndex:${this.swiperIndex}`);
      // if (this.homeData && this.homeData.banners.length > 0) {
      this.swiperIndex++;
      if (this.swiperIndex + 1 > this.homeData.banners.length) {
        this.swiperIndex = 0;
      }
      this.introShow = true;
      // }
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/base.scss";
@import "~@/assets/scss/mobile.scss";
.partner-item{
  display: flex;
  width: 180px;
  height: 120px;
  padding: 10px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  align-content: center;
  overflow: hidden;
  a {
    cursor: pointer;
  }
  img{
    width: 100%;
    height: auto;
  }
}
</style>
