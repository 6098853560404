<template>
  <div class="footer" :style="isMobile ? 'padding-left: 30px;' : ''">
    &copy;&nbsp;2020 GENERAL LININGS. All rights reserved.
  </div>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    isMobile: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/base.scss";
.footer {
  width: 100%;
  font-size: 12px;
  height: 50px;
  line-height: 50px;
  text-align: left;
  border-top: 1px dashed #ddd;
  color: #6e6d7a;
  box-sizing: border-box;
  overflow: hidden;
}
</style>
