<template>
  <div class="tb-width-full gallery" id="gallery">
    <div class="tb-wrap gallery-body">
      <div class="title-head">Our Gallery</div>
      <div class="title-sudhead">We have implemented more wonderful works</div>
      <!-- Gellery Wrap -->
      <div class="gallery-wrap">
        <!-- Gallery Item -->
        <template v-for="item in homeData.gallery">
          <div class="gallery-item" :key="'gallery-' + item.id" @click="showGallery(item.id)">
            <div class="thumb">
              <img :src="item.cover_url" />
            </div>
            <div class="intro">
              <div class="intro-title">{{ item.name }}</div>
              <div class="intro-content">
                {{ item.content }}
              </div>
            </div>
          </div>
        </template>
        <!-- //end Gallery Item -->
      </div>
      <!-- //end Gallery Items -->
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import * as api from '@/api';
const homeStore = createNamespacedHelpers('home');

export default {
  name: 'Gallery',
  data () {
    return {
    };
  },
  computed: {
    ...homeStore.mapGetters(['homeData'])
  },
  methods: {
    showGallery (itemId) {
      this.$router.push({
        path: 'gallery',
        query: { id: itemId }
      });
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/base.scss";
@import "~@/assets/scss/gallery.scss";
</style>
