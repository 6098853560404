import Vue from 'vue';
import VueScrollTo from 'vue-scrollto';

import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

import axios from 'axios';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faFacebook, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

axios.defaults.baseURL = '/server';
Vue.prototype.$axios = axios;

// Vue.use(VueAnalytics, {
//   id: 'UA-187931571-1',
//   router,
//   debug: {
//     enabled: true,
//     sendHitTask: true
//   }
// });

Vue.use(VueScrollTo);

require('vue2-animate/dist/vue2-animate.min.css');

library.add(faFacebook, faTwitter, faInstagram);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
