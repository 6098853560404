<template>
  <div id="app" class="tb-width-full">
    <!-- PC Layout -->
    <template v-if="isMobileDevice === false">
      <Desktop />
    </template>
    <!-- //end PC Layout -->
    <!-- Mobile Layout -->
    <template v-else>
      <Mobile />
    </template>
    <!-- //end Mobile Layout -->
    </div>
</template>

<script script="text/javascript">
import isMobile from 'ismobilejs';
import Mobile from '@/views/Mobile';
import Desktop from '@/views/Desktop';

export default {
  name: 'App',
  components: {
    Desktop,
    Mobile
  },
  created () {
  },
  mounted () {
  },
  data () {
    return {
      isMobileDevice: isMobile(window.navigator).any
    };
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/base.scss";
//@import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');
#app {
  //font-family: Helvetica, Avenir, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #222222;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
}
</style>
